<template>
    <h1 class="page-header">
        {{ $t( "pages.prices.headlines.pageHeadline" ) }}
    </h1>

    <h3 class="page-sub-header header-flex-row">
        <span :class="[smallScreenMode ? 'mb-2' : '', 'me-2']">
            <n-icon class="page-header-icon">
                <cert-icon />
            </n-icon>
            {{ $t( "pages.prices.headlines.certifiedOnly" ) }}
        </span>
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('/contact')"
        >
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </h3>
    <p class="body-text">
        <n-table class="vertical-align-top" striped>
            <tbody>
            <tr>
                <td class="counter-col">1</td>
                <td>{{ $t( "pages.prices.offers.offer01" ) }}</td>
                <td class="price-col">90 €</td>
            </tr>
            <tr>
                <td class="counter-col">2</td>
                <td>{{ $t( "pages.prices.offers.offer02" ) }}</td>
                <td class="price-col">155 €</td>
            </tr>
            <tr>
                <td class="counter-col">5</td>
                <td>{{ $t( "pages.prices.offers.offer03" ) }}</td>
                <td class="price-col">370 €</td>
            </tr>
            <tr>
                <td>
                    <n-icon>
                        <info-icon />
                    </n-icon>
                </td>
                <td colspan="2">
                    {{ $t( "pages.prices.includings.including" ) }}:<br>
                    {{ $t( "pages.prices.includings.including1" ) }}
                </td>
            </tr>
            </tbody>
        </n-table>
        <n-table class="vertical-align-top mt-4" striped>
            <tbody>
            <tr>
                <td class="counter-col">1</td>
                <td>{{ $t( "pages.prices.offers.offer01" ) }}</td>
                <td class="price-col">80 €</td>
            </tr>
            <tr>
                <td class="counter-col">2</td>
                <td>{{ $t( "pages.prices.offers.offer02" ) }}</td>
                <td class="price-col">135 €</td>
            </tr>
            <tr>
                <td class="counter-col">5</td>
                <td>{{ $t( "pages.prices.offers.offer03" ) }}</td>
                <td class="price-col">320 €</td>
            </tr>
            <tr>
                <td>
                    <n-icon>
                        <info-icon />
                    </n-icon>
                </td>
                <td colspan="2">
                    {{ $t( "pages.prices.includings.including" ) }}:<br>
                    {{ $t( "pages.prices.includings.including2" ) }}
                </td>
            </tr>
            </tbody>
        </n-table>
    </p>

    <h3 class="page-sub-header header-flex-row">
         <span :class="[smallScreenMode ? 'mb-2' : '', 'me-2']">
        <n-icon class="page-header-icon">
            <no-cert-icon />
        </n-icon>
        {{ $t( "pages.prices.headlines.noCertification" ) }}
         </span>
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('/contact')"
        >
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </h3>
    <p class="body-text">
        <n-table class="vertical-align-top" striped>
            <tbody>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <snorkel-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer04" ) }}</td>
                <td class="price-col">45 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <snorkel-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer05" ) }}</td>
                <td class="price-col">30 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer06" ) }}:<br>{{ $t( "pages.prices.offers.offer07" ) }}</td>
                <td class="price-col">110 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer06" ) }}:<br> {{ $t( "pages.prices.offers.offer08" ) }}</td>
                <td class="price-col">170 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <bubble-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer09" ) }}</td>
                <td class="price-col">100 €</td>
            </tr>
            </tbody>
        </n-table>
    </p>

    <h3 class="page-sub-header header-flex-row">
        <span :class="[smallScreenMode ? 'mb-2' : '', 'me-2']">
        <n-icon class="page-header-icon">
            <cert-icon />
        </n-icon>
        {{ $t( "pages.prices.headlines.padiCourses" ) }}
        </span>
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('/contact')"
        >
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </h3>
    <p class="body-text">
        <n-table class="vertical-align-top" striped>
            <tbody>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer10" ) }}</td>
                <td class="price-col">350 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer11" ) }}</td>
                <td class="price-col">490 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer12" ) }}</td>
                <td class="price-col">390 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer13" ) }}</td>
                <td class="price-col">360 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <dive-icon />
                    </n-icon>
                </td>
                <td>{{ $t( "pages.prices.offers.offer14" ) }}</td>
                <td class="price-col">190 €</td>
            </tr>
            <tr>
                <td class="counter-col">
                    <n-icon>
                        <info-icon />
                    </n-icon>
                </td>
                <td colspan="2">
                    {{ $t( "pages.prices.includings.including" ) }}:<br>
                    {{ $t( "pages.prices.includings.including3" ) }}
                </td>
            </tr>
            </tbody>
        </n-table>
    </p>

    <h3 class="page-sub-header header-flex-row">
        <span :class="[smallScreenMode ? 'mb-2' : '', 'me-2']">
            <n-icon class="page-header-icon">
                <vip-icon />
            </n-icon>
            {{ $t( "pages.prices.headlines.privateTour" ) }}
        </span>
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('/contact')"
        >
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </h3>
    <p class="body-text">
        {{ $t( "pages.prices.offers.offer15" ) }}
    </p>
</template>

<script>
import { Certificate24Filled as CertIcon } from '@vicons/fluent'
import { NonCertified as NoCertIcon, Information as InfoIcon } from '@vicons/carbon'
import {
    ScubaDivingFilled as DiveIcon,
    BubbleChartFilled as BubbleIcon,
    AddLocationAltOutlined as VipIcon
} from '@vicons/material'
import { ScubaMask as SnorkelIcon } from '@vicons/tabler'
import { mapGetters } from "vuex"

export default {
    components: {
        CertIcon, NoCertIcon, InfoIcon, DiveIcon, BubbleIcon, SnorkelIcon, VipIcon
    },
    computed: {
        ...mapGetters( [ 'getSmallScreenMode' ] ),
        smallScreenMode() {
            return this.getSmallScreenMode
        }
    },
    methods: {
        linkTo( path ) {
            this.$router.push( { path, query: this.$route.query } )
        }
    }
}
</script>

<style scoped>
.counter-col {
    width: 50px;
}

.price-col {
    white-space: nowrap;
    text-align: right;
}

.vertical-align-top td {
    vertical-align: top;
}
</style>